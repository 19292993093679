import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  // Elements,
  useStripe,
} from "@stripe/react-stripe-js";
import { navigate } from "gatsby";
import { RichText } from "prismic-reactjs";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
// import { Link } from "../../components/Link/Link";
import { Text } from "../../components/Text/Text";
import CheckImage from "../../images/checkout-check.png";
import DebitStep1Image from "../../images/checkout-debit-step-1.png";
import DebitStep2Image from "../../images/checkout-debit-step-2.png";
import CheckIcon from "../../images/check.png";
import DebitImage from "../../images/checkout-debit.png";
import NoteImage from "../../images/checkout-note.png";
import PaypalImage from "../../images/checkout-paypal.png";
import { color, typography } from "../../shared/style";
import useWindowSize from "../AcademyDetailContainer/useWindowSize";
import htmlSerializer from "../SpaceConntainer/RichtextHelper/CustomText";

const customStyles = {
  control: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      cursor: "pointer",
      height: isFocused ? 65 : 65,
      borderRadius: isFocused ? 50 : 50,
      paddingLeft: 22,
      border: 0,
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      borderRadius: 12,
      overflow: "hidden",
    };
  },
  indicatorsContainer: (styles, { isFocused }) => {
    return { ...styles, paddingRight: isFocused ? 26 : 26 };
  },
  indicatorSeparator: (styles) => {
    return { ...styles, width: 0 };
  },
};

const FormCheckout = ({ countries, locations, data, checkoutData }) => {
  const dataCheckout = checkoutData?.edges[0]?.node?.data || {};
  const { title, programs } =
    typeof sessionStorage !== "undefined" &&
      JSON.parse(sessionStorage.getItem("dataCheckout"))
      ? JSON.parse(sessionStorage.getItem("dataCheckout"))
      : {};

  const [width] = useWindowSize();
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [isDebitCard, setIsDebitCard] = useState(true);
  const [stepMobile, setStepMobile] = useState(1);
  const contryOptions = countries.map((co) => ({
    label: co.name,
    value: co.code,
  }));
  const locationOptions = dataCheckout?.campus_location?.map((lo) => ({
    label: `${lo?.location?.document?.data?.city?.text} (${lo?.location?.document?.data?.country?.text})`,
    value: lo?.location?.document?.data?.title?.text,
  }));
  //eslint-disable-next-line
  const defaultProgram = (programs && programs[0]) || { name: { text: "" } };
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: { program: defaultProgram.name.text } });

  const programField = watch("program");
  const { subValue, programName } = useMemo(() => {
    const program = (programs || []).find(
      (prog) => prog?.name?.text === programField
    ) || { price: {}, name: {} };
    return {
      subValue: program.price.text,
      programName: program.name.text,
    };
  }, [programField, programs]);
  const totalValue = useMemo(() => {
    return subValue;
  }, [subValue]);

  const handleOpenModalSuccess = () => {
    setOpenModalSuccess(!openModalSuccess);
  };

  const showInfomationDebitCard = (value) => {
    setIsDebitCard(value);
  };

  const handlePreStep = () => {
    setStepMobile(1);
  };

  // 1️⃣ Setup state to track client secret, errors and checkout status
  const [processing, setProcessing] = useState();
  const [error, setError] = useState();
  // const [disabled, setDisabled] = useState();
  const [succeeded, setSucceeded] = useState();

  // 2️⃣ Store reference to Stripe
  const stripe = useStripe();
  const elements = useElements();

  const submitPayment = async (data) => {
    if (width < 650 && stepMobile === 1) {
      setStepMobile(2);
    }
    //eslint-disable-next-line
    console.log('-------------', elements.getElement(CardNumberElement));
    const intent = await createPayment(data);
    const payment = await confirmPayment(intent);
  };
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  const createPayment = (data) => {
    // 3️⃣ Create PaymentIntent and fetch client secret as soon as the page loads
    return window
      .fetch("/.netlify/functions/checkout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: Math.round(totalValue * 100), // Specify amount here
          currency: "usd", // Specify currency here
          payment_method_types: ["card"],
          receipt_email: data.email,
          metadata: {
            email: data.email,
            program: programName,
            fullName: data.fullname,
            location: data.location.value,
            referer: data.connectBy.value,
            stressAddress: data.stressAddress,
            city: data.city,
            state: data.state,
            zipCode: data.zipCode,
            country: data.country.value,
          },
        }),
      })
      .then((res) => {
        return res.json();
      });
  };

  const handleChange = async (event) => {
    // 4️⃣ Listen for changes in the CardElement and display any errors as the customer types their card details
    // setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleGoback = () => {
    navigate(-1);
  };
  //eslint-disable-next-line
  const confirmPayment = async (intent) => {
    setProcessing(true);
    // 5️⃣ Confirm Card Payment.
    const metadata = intent.metadata;
    console.log(metadata);
    console.log('-------------', elements.getElement(CardNumberElement));
    const payload = await stripe.confirmCardPayment(intent.client_secret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          // include other billing details like customer name
          address: {
            postal_code: metadata.zipCode, // pass customer postal code
            city: metadata.city,
            country: metadata.country,
            state: metadata.state,
            line1: metadata.stressAddress,
            line2: "",
          },
          email: metadata.email,
          name: metadata.fullName,
          // phone: '',
        },
      },
      receipt_email: metadata.email,
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };

  useEffect(() => {
    if (succeeded) {
      setOpenModalSuccess(true);
    } else {
      setOpenModalSuccess(false);
    }
  }, [succeeded, error]);

  return (
    <WrapperSection>
      <HeaderWrap>
        <HeaderTitle
          tag="h3"
          font={typography.type.code}
          color={color.primary.blue}
          weight={typography.weight.regular1}
          align="center"
          size={typography.size.code}
          lineHeight={90}
          capital="uppercase"
        >
          {title && (
            <RichText render={title?.raw} htmlSerializer={htmlSerializer} />
          )}
        </HeaderTitle>
        <HeaderDescription
          tag="h5"
          font={typography.type.primary}
          color={color.secondary.danube}
          weight={typography.weight.bold}
          align="center"
          size={typography.size.m1}
          lineHeight={30}
        >
          <RichText
            render={data?.header.description.raw}
            htmlSerializer={htmlSerializer}
          />
        </HeaderDescription>
        {width <= 650 && (
          <ContainerProcessBar>
            <WrapImageStep onClick={handlePreStep}>
              <Image src={NoteImage} alt="image"></Image>
            </WrapImageStep>
            <WrapDot></WrapDot>
            <WrapImageStep style={{ width: "34px" }}>
              <Image
                src={stepMobile === 1 ? DebitStep1Image : DebitStep2Image}
                alt="image"
              ></Image>
            </WrapImageStep>
          </ContainerProcessBar>
        )}
      </HeaderWrap>
      <Form id="payment-form" onSubmit={handleSubmit(submitPayment)}>
        <Wrapper>
          {((width <= 650 && stepMobile === 1) || width > 650) && (
            <WrapperLeft>
              <BoxForm>
                <TitleBoxForm>
                  YES! I want to be the next startup Hero{" "}
                  <TitleBoxFormStar>*</TitleBoxFormStar>
                </TitleBoxForm>
                <Input
                  type="email"
                  {...register("email", { required: true })}
                  placeholder="Email"
                ></Input>
                {errors?.email?.type === "required" && (
                  <MessgeError>This field is required</MessgeError>
                )}
                <HeaderCourseSelection
                  font={typography.type.primary}
                  color={color.netraul.black100}
                  weight={typography.weight.bold}
                  size={typography.size.m1}
                  lineHeight={30}
                >
                  My program choice is
                </HeaderCourseSelection>
                {programs?.length > 0 &&
                  programs.map((prog) => (
                    <GroupRadio>
                      <Label htmlFor={prog?.name?.text}>
                        <Input
                          {...register("program", { required: true })}
                          id={prog?.name?.text}
                          type="radio"
                          value={prog?.name?.text}
                        ></Input>
                        <SpanRadio className="span-radio"></SpanRadio>
                        <SpanLabel>
                          {prog?.name?.text} (
                          <strong>US${prog?.price?.text}</strong>)
                        </SpanLabel>
                      </Label>
                    </GroupRadio>
                  ))}
                {errors?.program?.type === "required" && (
                  <MessgeError>This field is required</MessgeError>
                )}
              </BoxForm>

              <BoxForm>
                <TitleBoxForm>
                  The Draper Startup House Closest to me is{" "}
                  <TitleBoxFormStar>*</TitleBoxFormStar>
                </TitleBoxForm>
                <Controller
                  name="location"
                  control={control}
                  {...register("location", { required: true })}
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Choose a campus location"
                      styles={customStyles}
                      className="select-box"
                      options={locationOptions}
                    />
                  )}
                />
                {errors?.location?.type === "required" && (
                  <MessgeError>This field is required</MessgeError>
                )}
              </BoxForm>

              <BoxForm style={width <= 650 ? { marginBottom: 40 + "px" } : {}}>
                <TitleBoxForm
                  font={typography.type.code}
                  color={color.netraul.black100}
                  weight={typography.weight.normal}
                  size={typography.size.l1}
                  lineHeight={36}
                  capital="uppercase"
                >
                  MY Billing INFORMATION <TitleBoxFormStar>*</TitleBoxFormStar>
                </TitleBoxForm>
                <FormGroup>
                  <Input
                    type="text"
                    {...register("fullname", { required: true })}
                    placeholder="Full name"
                  ></Input>
                  {errors?.fullname?.type === "required" && (
                    <MessgeError>This field is required</MessgeError>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    type="text"
                    {...register("stressAddress", { required: true })}
                    placeholder="Street address"
                  ></Input>
                  {errors?.stressAddress?.type === "required" && (
                    <MessgeError>This field is required</MessgeError>
                  )}
                </FormGroup>
                <GroupFormRow>
                  <FormGroup>
                    <Input
                      type="text"
                      {...register("city", { required: true })}
                      placeholder="City"
                    ></Input>
                    {errors?.city?.type === "required" && (
                      <MessgeError>This field is required</MessgeError>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type="text"
                      {...register("state", { required: true })}
                      placeholder="State"
                    ></Input>
                    {errors?.state?.type === "required" && (
                      <MessgeError>This field is required</MessgeError>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type="text"
                      {...register("zipCode", { required: true })}
                      placeholder="Zip/Postal code"
                    ></Input>
                    {errors?.zipCode?.type === "required" && (
                      <MessgeError>This field is required</MessgeError>
                    )}
                  </FormGroup>
                </GroupFormRow>
                <Controller
                  name="country"
                  control={control}
                  {...register("country", { required: true })}
                  render={({ field }) => (
                    <Select
                      placeholder="Country"
                      styles={customStyles}
                      className="select-box"
                      {...field}
                      options={contryOptions}
                    />
                  )}
                />
                {errors?.country?.type === "required" && (
                  <MessgeError>This field is required</MessgeError>
                )}
              </BoxForm>

              <BoxForm>
                <TitleBoxForm>
                  I heard about Draper Startup House academy from
                </TitleBoxForm>
                {dataCheckout?.i_heard_about_draper_startup_house_academy_from ? dataCheckout?.i_heard_about_draper_startup_house_academy_from?.map((item, index) => {
                  return (
                    <GroupCheckbox key={index}>
                      <Label>
                        <Input
                          {...register("connectBy")}
                          value={item?.label?.text}
                          type="checkbox"
                        ></Input>
                        <SpanCheckbox className="span-checkbox"></SpanCheckbox>
                        <SpanLabelCheckbox>{item?.label?.text}</SpanLabelCheckbox>
                      </Label>
                    </GroupCheckbox>
                  )
                }) : (
                  <>
                    <GroupCheckbox>
                      <Label>
                        <Input
                          {...register("connectBy")}
                          value="Friend referral"
                          type="checkbox"
                        ></Input>
                        <SpanCheckbox className="span-checkbox"></SpanCheckbox>
                        <SpanLabelCheckbox>Friend referral</SpanLabelCheckbox>
                      </Label>
                    </GroupCheckbox>
                    <GroupCheckbox>
                      <Label>
                        <Input
                          {...register("connectBy")}
                          value="Social media"
                          type="checkbox"
                        ></Input>
                        <SpanCheckbox className="span-checkbox"></SpanCheckbox>
                        <SpanLabelCheckbox>Social media</SpanLabelCheckbox>
                      </Label>
                    </GroupCheckbox>
                    <GroupCheckbox>
                      <Label>
                        <Input
                          {...register("connectBy")}
                          value="Others"
                          type="checkbox"
                        ></Input>
                        <SpanCheckbox className="span-checkbox"></SpanCheckbox>
                        <SpanLabelCheckbox>Others</SpanLabelCheckbox>
                      </Label>
                    </GroupCheckbox>
                    <GroupCheckbox>
                      <Label>
                        <Input
                          {...register("connectBy")}
                          value="Slack"
                          type="checkbox"
                        ></Input>
                        <SpanCheckbox className="span-checkbox"></SpanCheckbox>
                        <SpanLabelCheckbox>Slack</SpanLabelCheckbox>
                      </Label>
                    </GroupCheckbox>
                    <GroupCheckbox>
                      <Label>
                        <Input
                          {...register("connectBy")}
                          value="Google search"
                          type="checkbox"
                        ></Input>
                        <SpanCheckbox className="span-checkbox"></SpanCheckbox>
                        <SpanLabelCheckbox>Google search</SpanLabelCheckbox>
                      </Label>
                    </GroupCheckbox>
                  </>
                )}
              </BoxForm>
            </WrapperLeft>
          )}

          <WrapperRight>
            {/* show Step 2 in mobile or show container form card in desktop */}
            {((stepMobile === 2 && width <= 650) || width > 650) && (
              <WrapFormCard
                style={
                  width <= 650
                    ? { display: "flex", flexDirection: "column-reverse" }
                    : { display: "flex", flexDirection: "column" }
                }
              >
                <BoxForm className="bg-blue">
                  <TitleBoxForm className="color-white">
                    ORDER summary
                  </TitleBoxForm>
                  <OrderItem>
                    <Text>Subtotal</Text>
                    <Text>${subValue} USD</Text>
                  </OrderItem>
                  <OrderItem>
                    <Text>Total</Text>
                    <Text>${totalValue} USD</Text>
                  </OrderItem>
                </BoxForm>

                <BoxForm className="bg-blue">
                  <TitleBoxForm className="color-white">
                    Payment information <TitleBoxFormStar>*</TitleBoxFormStar>
                  </TitleBoxForm>
                  <GroupRadio>
                    <Label htmlFor="debit" className="color-white">
                      <Input
                        id="debit"
                        {...register("paymentBy", { required: true })}
                        value="debit"
                        type="radio"
                        onClick={() => showInfomationDebitCard(true)}
                        checked={isDebitCard}
                      ></Input>
                      <SpanRadio
                        className="span-radio"
                        style={{
                          backgroundColor: "#fff",
                          border: "1px solid rgba(23, 41, 64, 0.3)",
                        }}
                      ></SpanRadio>
                      <ContainerImagenCard>
                        <Image src={DebitImage} alt="image"></Image>
                      </ContainerImagenCard>
                      <span>Debit/Credit Card</span>
                    </Label>
                  </GroupRadio>
                  <GroupRadio>
                    <Label htmlFor="paypal" className="color-white">
                      <Input
                        {...register("paymentBy", { required: true })}
                        id="paypal"
                        value="paypal"
                        type="radio"
                        onClick={() => showInfomationDebitCard(false)}
                      ></Input>
                      <SpanRadio
                        className="span-radio"
                        style={{
                          backgroundColor: "#fff",
                          border: "1px solid rgba(23, 41, 64, 0.3)",
                        }}
                      ></SpanRadio>
                      <ContainerImagenCard>
                        <Image src={PaypalImage} alt="image"></Image>
                      </ContainerImagenCard>
                      <span>Paypal</span>
                    </Label>
                  </GroupRadio>
                  {errors?.paymentBy?.type === "required" && (
                    <MessgeError>This field is required</MessgeError>
                  )}

                  {/* show Form to enter infomation debit card */}
                  {isDebitCard && (
                    <GroupFormPayment>
                      <FormGroup>
                        <CardNumberElement
                          className="card-input"
                          options={{
                            placeholder: "Card number (1234 1234 1234 1234)",
                            style: stripeEmentStyle,
                          }}
                          onChange={handleChange}
                        />
                      </FormGroup>
                      <GroupFormRow>
                        <FormGroup style={{ width: "50%" }}>
                          <CardExpiryElement
                            options={{
                              placeholder: "Expiration date (MM/YY) ",
                              style: stripeEmentStyle,
                            }}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup style={{ width: "50%" }}>
                          <CardCvcElement
                            className="card-ccv"
                            options={{
                              placeholder: "Security code (123)",
                              style: stripeEmentStyle,
                            }}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </GroupFormRow>
                    </GroupFormPayment>
                  )}
                  {/* Show any error that happens when processing the payment */}
                  {error && <ErrorAlert role="alert">{error}</ErrorAlert>}
                  {width > 650 && (
                    <GroupButton>
                      <ButtonSubmit
                        type="tertiary"
                        id="submit"
                        disable={processing}
                      >
                        <span id="button-text">
                          {processing ? "Processing..." : "Place order"}
                        </span>
                      </ButtonSubmit>
                    </GroupButton>
                  )}
                </BoxForm>
              </WrapFormCard>
            )}

            {/* button submit in mobile */}
            {width <= 650 && (
              <GroupButton style={{ marginTop: 20 + "px" }}>
                <ButtonSubmit type="tertiary" id="submit">
                  {!isDebitCard || stepMobile === 1 ? "Next" : "Place order"}
                </ButtonSubmit>
              </GroupButton>
            )}
            {/* {!(width <= 650 && stepMobile === 2) && (
              <BoxForm className="bg-white-border ">
                <TitleBoxForm>need to talk to someone?</TitleBoxForm>
                <DescriptionBox
                  font={typography.type.primary}
                  color={color.netraul.black100}
                  weight={typography.weight.normal}
                  size={typography.size.s3}
                  lineHeight={26}
                >
                  Schedule a 15-minute consultation with an Entrepreneur in
                  Residence to get more information about Draper Startup House
                  Academy, Draper University, and this program.
                </DescriptionBox>
                <LinkScheduce>Schedule a call</LinkScheduce>
              </BoxForm>
            )} */}
          </WrapperRight>
        </Wrapper>
      </Form>

      <Modal display={openModalSuccess ? "block" : "none"}>
        <Overlay onClick={handleOpenModalSuccess}></Overlay>
        <BoxCheckSuccess>
          <WrapCheckboxImage>
            <DoneIcon src={CheckImage} alt="image"></DoneIcon>
          </WrapCheckboxImage>
          <TitleBoxCheckSuccess
            tag="h5"
            font={typography.type.code}
            color={color.netraul.black100}
            weight={typography.weight.regular1}
            align="center"
            size={typography.size.l1}
            lineHeight={36}
            capital="uppercase"
          >
            payment successful
          </TitleBoxCheckSuccess>
          <DescriptionBoxCheckSuccess
            font={typography.type.primary}
            color={color.netraul.black100}
            weight={typography.weight.normal}
            align="center"
            size={typography.size.s3}
            lineHeight={26}
          >
            A confirmation was emailed to you. Before the course starts, you’ll
            receive access information via email.
          </DescriptionBoxCheckSuccess>
          <ActionBoxCheckSuccess>
            {/* <ButtonCancel onClick={handleGoback}>Cancel</ButtonCancel> */}
            <ButtonGoBack onClick={handleGoback} type="primary">
              Go back to Academy
            </ButtonGoBack>
          </ActionBoxCheckSuccess>
        </BoxCheckSuccess>
      </Modal>
    </WrapperSection>
  );
};

const WrapFormCard = styled.div``;
const WrapDot = styled.div`
  flex-grow: 1;
  border: 1px dashed rgba(35, 35, 39, 0.5);
  margin: 0 12px;
`;
const WrapImageStep = styled.div`
  width: 24px;
  height: 24px;
`;
const ContainerProcessBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
`;
const MessgeError = styled.p`
  color: red;
  font-size: 14px;
  padding-left: 22px;
`;
const ContainerImagenCard = styled.div`
  width: 20px;
  margin-right: 10px;
`;

const ButtonGoBack = styled(Button)`
  padding: 20px 45px;
  border-radius: 40px;
`;
// const ButtonCancel = styled(Link)`
//   font-weight: bold;
//   font-size: 16px;
//   padding: 0 36px;
//   color: ${color.primary.blue};
//   display: inline-block;
//   @media (max-width: 1200px) {
//   }
//   @media (max-width: 992px) {
//   }
//   @media (max-width: 650px) {
//     margin-top: 24px;
//   }
// `;
const ActionBoxCheckSuccess = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    margin-top: 40px;
    flex-direction: column-reverse;
  }
`;

const DescriptionBoxCheckSuccess = styled(Text)``;
const TitleBoxCheckSuccess = styled(Text)`
  padding: 0 0px 12px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  max-width: 21px;
  max-height: 21px;
  object-fit: cover;
`;
const DoneIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const WrapCheckboxImage = styled.div`
  max-width: 109px;
  max-height: 109px;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
`;
const BoxCheckSuccess = styled.div`
  padding: 40px 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 29vh;
  left: 50%;
  transform: translatex(-50%);
  z-index: 100;
  width: 478px;
  box-sizing: border-box;
  background-color: ${color.primary.white};
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    padding: 40px 36px;
    top: 21.56vh;
    width: 334px;
  }
`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
`;
const Modal = styled.div`
  display: ${(props) => props.display};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  .boxform-model {
    position: fixed;
    left: 50%;
    transform: translatex(-50%);
    z-index: 100;
    width: 512px;
    div:last-child {
      margin-top: 36px;
    }
  }
`;
// const LinkScheduce = styled(Link)`
//   color: ${color.primary.blue};
//   border-bottom: 1px solid ${color.primary.blue};
//   display: inline-block;
//   font-weight: bold;
//   @media (max-width: 650px) {
//     margin-top: 2px;
//   }
// `;
// const DescriptionBox = styled(Text)`
//   display: block;
//   margin-bottom: 28px;
// `;
const ButtonSubmit = styled(Button)`
  border-radius: 40px;
  width: 100%;
`;
const GroupButton = styled.div`
  margin-top: 36px;
`;
const GroupFormPayment = styled.div`
  margin-top: 36px;
  margin-bottom: -20px;
`;
const OrderItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
  span:first-child {
    font-family: "Oxygen";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #fff;
  }
  span:last-child {
    font-family: "Oxygen";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #fff;
  }
`;
const GroupCheckbox = styled.div`
  display: inline-flex;
  align-items: center;
  width: 33%;
  margin-top: 16px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    width: 50%;
    box-sizing: border-box;
    padding-right: 8px;
  }
`;
const Label = styled.label`
  font-family: Oxygen;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
`;
const GroupRadio = styled.div`
  margin-top: 14px;
`;
const HeaderCourseSelection = styled(Text)`
  display: block;
  margin-top: 28px;
  margin-bottom: 20px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    font-size: 20px !important;
    line-height: 30px;
  }
`;
const Input = styled.input`
  &[type="text"],
  &[type="email"] {
    background: ${color.primary.white};
    border-radius: 50px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    width: 100%;
    height: 65px;
    font-family: Oxygen;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: rgba(35, 35, 39, 0.5);
    padding-left: 26px;
    box-sizing: border-box;
    &:focus {
      outline: none;
      border-color: #2684ff;
      box-shadow: 0 0 1 #2684ff;
    }
  }
  &[type="radio"],
  &[type="checkbox"] {
    display: none;
  }
  &[type="radio"]:checked ~ .span-radio::before {
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${color.primary.blue};
    position: absolute;
    top: 5px;
    left: 5px;
  }
  &[type="radio"]:checked ~ .span-radio {
    background-color: ${color.primary.white};
  }
  .span-checkbox {
    position: relative;
  }
  &[type="checkbox"]:checked ~ .span-checkbox {
    background-color: ${color.primary.blue};
    &::before {
      position: absolute;
      background-image: url(${CheckIcon});
      background-size: cover;
      width: 14px;
      height: 14px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      color: #fff;
      content: "";
    }
  }
`;
const SpanCheckbox = styled.span`
  min-width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: rgba(23, 41, 64, 0.1);
  border: 1px solid rgba(23, 41, 64, 0.3);
  position: relative;
  display: inline-block;
  margin-right: 12px;
  border-radius: 5px;
`;
const SpanRadio = styled.span`
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgba(23, 41, 64, 0.1);
  border: 1px solid rgba(23, 41, 64, 0.3);
  position: relative;
  display: inline-block;
  margin-right: 12px;
`;
const SpanLabel = styled(Text)`
  color: ${color.netraul.black100};
  font-weight: 400;
  font-family: ${typography.type.primary};
`;
const SpanLabelCheckbox = styled(Text)`
  color: ${color.netraul.black100};
  font-weight: 400;
  font-family: ${typography.type.primary};
`;
const GroupFormRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
    flex-direction: column;
    gap: 0;
    div {
      width: 100% !important;
    }
  }
  @media (max-width: 650px) {
    flex-direction: column;
    gap: 10px;
  }
`;
const FormGroup = styled.div`
  margin-bottom: 20px;
`;
const TitleBoxFormStar = styled.sup`
  color: red;
`;
const TitleBoxForm = styled(Text)`
  font-family: "Alternate Gothic No1 D";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: -0.015em;
  text-transform: uppercase;
  color: #232327;
  margin-bottom: 31px;
  display: block;
  @media (max-width: 1200px) {
    font-size: 36px !important;
    line-height: 36px;
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
  }
`;
const BoxForm = styled.div`
  background-color: ${color.primary.blue10};
  padding: 30px 32px 50px;
  margin-bottom: 16px;
  @media (max-width: 1200px) {
    padding: 40px 20px;
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
  }
  .select-box div div div {
    color: ${color.netraul.black100};
  }
`;
const WrapperRight = styled.div`
  flex: 0 0 41.667%;
  max-width: 41.667%;
  box-sizing: border-box;
  padding: 0 16px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px;
  }
`;
const WrapperLeft = styled.div`
  flex: 0 0 58.333%;
  max-width: 58.333%;
  box-sizing: border-box;
  padding: 0 16px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  box-sizing: border-box;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    margin: 0;
  }
`;
const Form = styled.form`
  margin-top: 80px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    margin-top: 30px;
  }
  .StripeElement {
    background: ${color.primary.white};
    border-radius: 50px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    width: 100%;
    height: 65px;
    font-family: Oxygen;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: rgba(35, 35, 39, 0.5);
    padding: 20px 15px 20px 26px;
    box-sizing: border-box;
  }
`;
const HeaderDescription = styled(Text)`
  div {
    margin-top: 24px;
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    font-size: 16px !important;
    line-height: 26px;
    font-weight: normal;
  }
`;
const HeaderTitle = styled(Text)`
  display: block;
  color: #204370 !important;
  font-weight: 500;
  span {
    color: #204370 !important;
    font-weight: 500;
  }
  @media (max-width: 1200px) {
    font-size: 72px !important;
    line-height: 72px;
    span {
      font-size: 72px !important;
      line-height: 72px;
    }
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    font-size: 62px !important;
    line-height: 62px;
    span {
      font-size: 62px !important;
      line-height: 62px;
    }
  }
`;
const HeaderWrap = styled.div``;
const WrapperSection = styled.div`
  max-width: 1500px;
  margin-top: 170px;
  margin-bottom: 150px;
  padding: 0 131px;

  @media (max-width: 1200px) {
    padding: 0 40px;
  }
  @media (max-width: 992px) {
    padding: 0 30px;
  }
  @media (max-width: 650px) {
    margin-top: 150px;
    margin-bottom: 80px;
    padding: 0 20px;
  }
  .bg-blue {
    background-color: ${color.primary.blue};
  }
  .color-white {
    color: ${color.primary.white};
  }
  .bg-white-border {
    background-color: ${color.primary.white};
    border: 1px solid ${color.primary.blue40};
    @media (max-width: 1200px) {
    }
    @media (max-width: 992px) {
    }
    @media (max-width: 650px) {
      margin-top: 60px;
    }
  }
  .select-box {
    & > div > div:nth-child(2) {
      padding-right: 12px;
    }
  }
`;

const ErrorAlert = styled("div")`
  color: #FFCC00;
  margin-top: 20px;
`;

const stripeEmentStyle = {
  base: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "transparent",
    width: "100%",
    height: "65px",
    fontFamily: "Oxygen",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "26px",
    color: "rgba(35, 35, 39, 0.5)",
    paddingLeft: "26px",
    boxSizing: "border-box",
    ":focus": {
      outline: "none",
      borderColor: "#2684ff",
      boxShadow: "0 0 1 #2684ff",
    },
  },
};

FormCheckout.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.shape({
      title: PropTypes.shape(),
      description: PropTypes.shape(),
    }),
  }),
};

FormCheckout.defaultProps = {
  title: {
    raw: [
      {
        type: "preformatted",
        text: "Fundamentals of entrepreneurship",
        spans: [],
      },
    ],
  },
  programs: [],
  data: {
    header: {
      title: {
        raw: [
          {
            type: "preformatted",
            text: "Fundamentals of entrepreneurship",
            spans: [],
          },
        ],
      },
      description: {
        raw: [
          {
            type: "preformatted",
            text: "Choose a package and check out",
            spans: [],
          },
        ],
      },
    },
  },
};

export default FormCheckout;
