import React from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { Sidebar } from "../components/Sidebar";
import { CheckoutContainer } from "../containers/CheckoutContainer/index";
import FooterContainer from "../containers/FooterContainer";
import { Header } from "../containers/HeaderContainer";
import useApp, { withAppContext } from "../contexts/context";
import { useQueryCheckout } from "../hooks/useQueryCheckout";
import { useSiteMetadata } from "../hooks/useQuerydata";

const Checkout = ({ location }) => {
  
  const { toggle, handleToggleLayout } = useApp();
  const { allPrismicFooter } = useSiteMetadata();
  const {
    // allPrismicSeo,
    allDataJson,
    allPrismicLocationSpace,
    allPrismicHeader,
    allPrismicCheckout
  } = useQueryCheckout();
  const dataJson = allDataJson.nodes;
  const dataHeader = allPrismicHeader?.nodes[0]?.data;
  const countriesDataNode = dataJson.find((n) => n.countries);
  const countries = countriesDataNode.countries;
  const locations = allPrismicLocationSpace.nodes;
  //const { title, programs } = (location || {}).state || {};
  const { seo } =
    typeof sessionStorage !== "undefined" &&
    JSON.parse(sessionStorage.getItem("dataCheckout"))
      ? JSON.parse(sessionStorage.getItem("dataCheckout"))
      : {};
  const theme = {
    bgColor: "white",
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout
        isHomepage
        toggle={toggle}
        toggleLayout={handleToggleLayout}
        header={<Header toggleLayout={handleToggleLayout} data={dataHeader} />}
        footer={<FooterContainer data={allPrismicFooter?.nodes[0]?.data} />}
        sidebar={
          <Sidebar
            toggle={toggle}
            handleToggle={handleToggleLayout}
            dataHeader={dataHeader}
            dataFooter={allPrismicFooter?.nodes[0]?.data}
          />
        }
        // seo={<SEO data={allPrismicSeo?.edges[0]?.node?.data?.body} />}
        seo={<SEO data={seo} />}
      >
        <CheckoutContainer locations={locations} countries={countries} checkoutData={allPrismicCheckout}/>
      </Layout>
    </ThemeProvider>
  );
};

export default withAppContext(Checkout);
